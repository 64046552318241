import { Box, Container, Grid } from "@mui/material";
import LogoEximPlanet from "./LogoEximPlanet";

const Footer = () => {
    return (
        <Box bgcolor="#F2F2F2">
            <Container>
                <Grid container>
                    <Grid item md={10} xs={8}>
                        <Box my={2}>
                            <LogoEximPlanet width={150} />
                        </Box>
                    </Grid>
                    <Grid md={2} xs={4} item alignSelf="center">
                        Designed by <a href="https://www.hionstudios.com?utm_source=eximplanet.co&utm_medium=client" target="_blank" rel="noreferrer">Hion Studios</a>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
export default Footer;