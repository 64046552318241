import { createTheme, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './view/Home';
function App() {
  const theme = createTheme({
    typography: {
      subtitle1: {
        fontSize: 18
      },
      h1: {
        fontFamily: 'Jost, sans-serif',
      },
      h2: {
        fontFamily: 'Jost, sans-serif',
      },
      h3: {
        fontFamily: 'Jost, sans-serif',
      },
      h4: {
        fontFamily: 'Jost, sans-serif',
      },
      h5: {
        fontFamily: 'Jost, sans-serif',
      }
    },
    palette: {
      type: 'light',
      primary: {
        main: '#4DBF3B',
        contrastText: '#0C1D25',
        dark: '#A3D930',
      },
      secondary: {
        main: '#3f51b5',
      },
      dark: '#0C1D25',
      brand: '#4DBF3B',
      text: {
        primary: '#0D0D0D',
      },
      background: {
        default: '#FFFFFF',
        paper: '#F2F2F2'
      }
    },
    shape: {
      borderRadius: 8,
    },
  });

  return (
    <StyledEngineProvider>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <CssBaseline />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
            </Routes>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
