import { Box } from "@mui/material"
import AboutUs from "../ui-component/AboutUs"
import ContactUs from "../ui-component/ContactUs"
import Footer from "../ui-component/Footer"
import Hero from "../ui-component/Hero"
import IncoTerms from "../ui-component/IncoTerms"
import NavBar from "../ui-component/NavBar"
import Services from "../ui-component/Services"
const Home = () => {
    return (
        <Box>
            <NavBar />
            <Hero />
            <AboutUs />
            <Services />
            <IncoTerms />
            <ContactUs />
            <Footer />
        </Box>
    )
}

export default Home