import { LoadingButton } from "@mui/lab";
import { Box, Container, FormControl, FormHelperText, Grid, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
const ContactUs = () => {
    const { enqueueSnackbar } = useSnackbar();
    const constructFormData = values => {
        const formData = new FormData()
        for (let field in values) {
            let value = values[field]
            formData.set(field, value)
        }
        return formData
    }
    return (
        <Box py={2} pb={10} id="contact">
            <Container>
                <Typography mb={3} variant="h3" fontWeight="600" color="dark" align="center" mt={4}>Contact Us</Typography>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <Typography variant="h3" mb={2}>Head Office</Typography>
                        <Typography variant="h5" fontWeight={600}>Exim Planet</Typography>
                        <Typography variant="h6">No.:122, Subbiahpuram 4th St.</Typography>
                        <Typography variant="h6">Tuticorin - 628003, Tamilnadu, India</Typography>
                        <Grid container>
                            <Grid item xs={3} md={2}>
                                <Typography variant="h6">Name: </Typography>
                            </Grid>
                            <Grid item xs={9} md={10}>
                                <Typography variant="h6">Parthasarathi S</Typography>
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <Typography variant="h6">Email: </Typography>
                            </Grid>
                            <Grid item xs={9} md={10}>
                                <Typography variant="h6"><a href="mailto://admin@eximplanet.co">admin@eximplanet.co</a></Typography>
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <Typography variant="h6">Tel: </Typography>
                            </Grid>
                            <Grid item xs={9} md={10}>
                                <Typography variant="h6"><a href="tel://+914614820729">+91 461 4820729</a></Typography>
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <Typography variant="h6">Phone: </Typography>
                            </Grid>
                            <Grid item xs={9} md={10}>
                                <Typography variant="h6"><a href="tel://+919884166656">+91 98841 66656</a></Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Typography variant="h4" mb={2} textAlign="center">Write to Us</Typography>
                        <Formik
                            validationSchema={Yup.object().shape({
                                name: Yup.string().max(100).required("Enter your name"),
                                phone: Yup.number().nullable(true).min(6000000000, "Invalid Number")
                                    .max(9999999999, "Invalid Number").required("Enter your Phone Number"),
                                email: Yup.string().email().max(100).required('Email is required'),
                                message: Yup.string().max(300).required("Enter the Message")
                            })}
                            onSubmit={async (values, { setSubmitting, resetForm }) => {
                                setSubmitting(true)
                                return await fetch(`https://api.hionstudios.com/api/eximplanet/contact`, {
                                    method: 'post',
                                    body: constructFormData(values)
                                })
                                    .then(res => res.json())
                                    .then(res => {
                                        if (res.status === 'success') {
                                            enqueueSnackbar('Message Sent Successfully', { variant: 'success' })
                                            resetForm();
                                        } else {
                                            enqueueSnackbar('Exception occurred', { variant: 'error' })
                                            setSubmitting(false)
                                        }
                                    })
                                    .catch(() => {
                                        enqueueSnackbar('Error occurred', { variant: 'error' })
                                        setSubmitting(false)
                                    })
                            }}
                            initialValues={{
                                name: '',
                                phone: '',
                                email: '',
                                message: ''
                            }}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                values
                            }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth error={Boolean(touched.name && errors.name)}>
                                                <TextField
                                                    required
                                                    color="dark"
                                                    id="name"
                                                    type="text"
                                                    value={values.name}
                                                    name="name"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="Name"
                                                />
                                                {touched.name && errors.name && (
                                                    <FormHelperText error id="error-name">
                                                        {errors.name}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth error={Boolean(touched.phone && errors.phone)}>
                                                <TextField
                                                    required
                                                    id="Phone"
                                                    type="number"
                                                    value={values.phone}
                                                    name="phone"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="Phone Number"
                                                />
                                                {touched.phone && errors.phone && (
                                                    <FormHelperText error id="error-Phone">
                                                        {errors.phone}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth error={Boolean(touched.email && errors.email)}>
                                                <TextField
                                                    required
                                                    id="email"
                                                    type="email"
                                                    value={values.email}
                                                    name="email"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="Business Email"
                                                />
                                                {touched.email && errors.email && (
                                                    <FormHelperText error id="error-Email">
                                                        {errors.email}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth error={Boolean(touched.message && errors.message)}>
                                                <TextField
                                                    required
                                                    multiline={true}
                                                    id="message"
                                                    type="text"
                                                    value={values.message}
                                                    name="message"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="Message"
                                                />
                                                {touched.message && errors.message && (
                                                    <FormHelperText error id="error-message">
                                                        {errors.message}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {errors.submit && (
                                        <Box mt={3}>
                                            <FormHelperText id='error-submit' error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                    <Box mt={2}>
                                        <LoadingButton
                                            loading={isSubmitting}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                        >
                                            Send Message
                                        </LoadingButton>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>

            </Container>
        </Box>
    )
}
export default ContactUs
