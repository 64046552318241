import { Box, Container, Grid, Typography } from "@mui/material";
import ImgFluid from "./ImgFluid";

const Services = () => {
    return (
        <Box mb={4} id="services">
            <Container>
                <Typography variant="h3" fontWeight="600" color="dark" align="center" my={4}>Services</Typography>

                <Grid container spacing={5} alignItems="center">
                    <Grid item md={4}>
                        <Box>
                            <Box mb={1}>
                                <ImgFluid src="/img/international-frieght.jpg" />
                            </Box>
                            <Typography variant="h5" fontWeight="bold" textAlign="center">International Frieght</Typography>
                        </Box>
                    </Grid>

                    <Grid item md={4}>
                        <Box>
                            <Box mb={1}>
                                <ImgFluid src="/img/frieght-consolidation.jpg" />
                            </Box>
                            <Typography variant="h5" fontWeight="bold" textAlign="center">Freight Consolidation</Typography>
                        </Box>
                    </Grid>

                    <Grid item md={4}>
                        <Box>
                            <Box mb={1}>
                                <ImgFluid src="/img/customs-brokerage.jpg" />
                            </Box>
                            <Typography variant="h5" fontWeight="bold" textAlign="center">Customs Brokerage</Typography>
                        </Box>
                    </Grid>

                    <Grid item md={4}>
                        <Box>
                            <Box mb={1}>
                                <ImgFluid src="/img/project-cargo.jpg" />
                            </Box>
                            <Typography variant="h5" fontWeight="bold" textAlign="center">Project cargo</Typography>
                        </Box>
                    </Grid>

                    <Grid item md={4}>
                        <Box>
                            <Box mb={1}>
                                <ImgFluid src="/img/warehousing.jpg" />
                            </Box>
                            <Typography variant="h5" fontWeight="bold" textAlign="center">Warehousing</Typography>
                        </Box>
                    </Grid>

                    <Grid item md={4}>
                        <Box>
                            <Box mb={1}>
                                <ImgFluid src="/img/domestic-freight.jpg" />
                            </Box>
                            <Typography variant="h5" fontWeight="bold" textAlign="center">Domestic Freight</Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Typography variant="h4" fontWeight="600" color="dark" align="center" my={4}>Value Added Services</Typography>

                <Grid container spacing={5} alignItems="center">
                    <Grid item md={3}>
                        <Box>
                            <Box mb={1}>
                                <ImgFluid src="/img/cargo-insurance.jpg" />
                            </Box>
                            <Typography variant="h6" textAlign="center">Cargo Insurance</Typography>
                        </Box>
                    </Grid>

                    <Grid item md={3}>
                        <Box>
                            <Box mb={1}>
                                <ImgFluid src="/img/land-cargo-insurance.jpg" />
                            </Box>
                            <Typography variant="h6" textAlign="center">Land Cargo Insurance</Typography>
                        </Box>
                    </Grid>

                    <Grid item md={3}>
                        <Box>
                            <Box mb={1}>
                                <ImgFluid src="/img/marine-cargo-insurance.jpg" />
                            </Box>
                            <Typography variant="h6" textAlign="center">Marine Cargo Insurance</Typography>
                        </Box>
                    </Grid>

                    <Grid item md={3}>
                        <Box>
                            <Box mb={1}>
                                <ImgFluid src="/img/packaging-solutions.jpg" />
                            </Box>
                            <Typography variant="h6" textAlign="center">Packaging Solutions</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
export default Services;