import { Box, Container, Typography, Grid } from "@mui/material"
import ImgFluid from "./ImgFluid"

const AboutUs = () => {
    return (
        <Box bgcolor="#F2F2F2" py={2} id="about">
            <Container>
                <Typography variant="h3" fontWeight="600" color="dark" align="center" my={4}>About Us</Typography>
                <Grid container spacing={5} alignItems="center">
                    <Grid item md={4}>
                        <Box>
                            <ImgFluid src="/logo-horizontal.png" alt="" />
                        </Box>
                    </Grid>
                    <Grid item md={8}>
                        <Box>
                            <Typography variant="h4" fontWeight="600" color="dark" mb={2}>
                                One stop solution for all your supply chain needs
                            </Typography>
                            <Typography variant="subtitle1" color="dark">
                                Exim Planet provides you with a wide range of logistics solutions across the complete supply chain by consulting and designing through Freight Forwarding, Warehousing and Distribution Services to integrated information management and e-Commerce support.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default AboutUs