import { Box } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import ImgFluid from "./ImgFluid";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { BrowserView, MobileView } from "react-device-detect";
const Hero = () => {
    const carouselOptions = {
        showArrows: true,
        infiniteLoop: true,
        showThumbs: false,
        autoPlay: true,
        emulateTouch: true,
    }
    return (
        <Box>
            <BrowserView>
                <Carousel {...carouselOptions}>
                    <Box>
                        <ImgFluid src="/img/hero/web/1.jpg" />
                    </Box>
                    <Box>
                        <ImgFluid src="/img/hero/web/2.jpg" />
                    </Box>
                    <Box>
                        <ImgFluid src="/img/hero/web/3.jpg" />
                    </Box>
                    <Box>
                        <ImgFluid src="/img/hero/web/4.jpg" />
                    </Box>
                </Carousel>
            </BrowserView>
            <MobileView>
                <Carousel {...carouselOptions}>
                    <Box>
                        <ImgFluid src="/img/hero/mob/1.jpg" />
                    </Box>
                    <Box>
                        <ImgFluid src="/img/hero/mob/2.jpg" />
                    </Box>
                    <Box>
                        <ImgFluid src="/img/hero/mob/3.jpg" />
                    </Box>
                    <Box>
                        <ImgFluid src="/img/hero/mob/4.jpg" />
                    </Box>
                </Carousel>
            </MobileView>
        </Box>

    )
}

export default Hero;