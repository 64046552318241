import { Box, Container, Typography } from "@mui/material"

const IncoTerms = () => {
    return (
        <Box bgcolor="#F2F2F2" py={3}>
            <Container>
                <Typography variant="h3" fontWeight="600" color="dark" align="center" my={4}>INCOTERMS 2020</Typography>
                <Typography>
                    Incoterms® play such a vital part in global trade. But buyers and sellers must clearly understand how they work and their obligations along the supply chain. Incoterms® are the selling terms that the buyer and seller of goods both agree to during international transactions. These rules are accepted by governments and legal authorities (International Chamber of Commerce) around the world. Understanding Incoterms® is a vital part of International Trade because they clearly state which tasks, costs and risks are associated with the buyer and the seller. The Incoterm® states when the seller’s costs and risks are transferred onto the buyer. It is also important to understand that not all rules apply in all cases.
                </Typography>
            </Container>
        </Box>
    )
}
export default IncoTerms