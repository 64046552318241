import { AppBar, Box, Button, Container, Stack, Toolbar, Typography } from "@mui/material";
import { isBrowser } from "react-device-detect";
import { Link, useLocation } from "react-router-dom";
import LogoEximPlanet from './LogoEximPlanet';


const NavBar = () => {
    const { hash } = useLocation()
    const scroll = id => () => {
        var ele = document.getElementById(id);
        window.scrollTo(ele.offsetLeft, ele.offsetTop)
    }
    return (
        <Box>
            <AppBar sx={{
                backgroundColor: '#FFFFFF',
                color: '#0D0D0D'
            }} position="sticky">
                <Toolbar>
                    <Container sx={{
                        display: 'flex'
                    }}>
                        <Link to="/">
                            <LogoEximPlanet
                                style={{
                                    marginTop: '.75rem',
                                    marginBottom: '.55rem'
                                }} width={200} />
                        </Link>
                        {isBrowser && <Stack direction="row" spacing={2} mx="auto">
                            <Button size="large" variant="text" component={Link} to="/">
                                <Typography fontWeight="bold" color={hash === '' ? 'brand' : '#0D0D0D'} fontFamily="Jost">
                                    Home
                                </Typography>
                            </Button>
                            <Button size="large" variant="text" component={Link} to="#about" onClick={scroll('about')}>
                                <Typography fontWeight="bold" color={hash === '#about' ? 'brand' : '#0D0D0D'} fontFamily="Jost">
                                    About Us
                                </Typography>
                            </Button>
                            <Button size="large" variant="text" component={Link} to="#services" onClick={scroll('services')}>
                                <Typography fontWeight="bold" color={hash === '#services' ? 'brand' : '#0D0D0D'} fontFamily="Jost">
                                    Services
                                </Typography>
                            </Button>
                            <Button size="large" variant="text" component={Link} to="#contact" onClick={scroll('contact')}>
                                <Typography fontWeight="bold" color={hash === '#contact' ? 'brand' : '#0D0D0D'} fontFamily="Jost">
                                    Contact
                                </Typography>
                            </Button>
                        </Stack>
                        }
                    </Container>
                </Toolbar>
            </AppBar>
        </Box >
    )
}

export default NavBar